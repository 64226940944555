import * as Sentry from '@sentry/browser';
import { appRouterServerSideApi } from './appRouterServerSideApi';
import { clientApi } from './clientApi';

export async function getPhysicalOrders(cookies) {
  try {
    return await appRouterServerSideApi('/ecommerce/admin/physical_order/all', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getSubscriptions(cookies) {
  try {
    return await appRouterServerSideApi('/ecommerce/admin/subscription/all', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updatePhysicalOrder(physicalOrderId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/physical_order/update/${physicalOrderId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getEnterpriseOrganizations(cookies) {
  try {
    return await appRouterServerSideApi('/profile/admin/organizations/all', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getEnterpriseOrganizationLinkedProfiles(organizationId) {
  try {
    return await clientApi(`/license/admin/profile_organization/${organizationId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateEnterpriseOrganization(organizationId, payload) {
  try {
    return await clientApi(
      `/profile/admin/organizations/${organizationId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function lookupProfiles(payload) {
  try {
    return await clientApi('/profile/admin/profile/search', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getEnterpriseBundles() {
  try {
    return await clientApi('/license/admin/enterprise/bundles', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateEnterpriseLicense(payload) {
  try {
    return await clientApi(
      '/license/admin/enterprise/update_enterprise_license',
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCustomerSubscriptionDetails(customer_id) {
  try {
    return await clientApi(`/ecommerce/admin/subscription/details/${customer_id}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function cancelSubscription(subscriptionId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/subscription/cancel/${subscriptionId}`,
      'DELETE',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPhysicalProducts(cookies) {
  try {
    return await appRouterServerSideApi('/ecommerce/admin/physical_products/all', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPhysicalProductDetails(physicalProductId) {
  try {
    return await clientApi(
      `/ecommerce/admin/physical_products/details/${physicalProductId}`,
      'GET',
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getProfiles(cookies) {
  try {
    return await appRouterServerSideApi('/profile/admin/profiles', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function adminDeleteProfile(profileId) {
  try {
    return await clientApi(`/profile/admin/profiles/${profileId}`, 'PATCH');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function adminResendValidationEmail(profileId) {
  try {
    return await clientApi(`/profile/admin/email/resend_validation/${profileId}`, 'POST');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function isOrganizationAdmin(profileId) {
  try {
    return await clientApi(`/profile/admin/profiles/${profileId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getProfileSubscriptions(profileId) {
  try {
    return await clientApi(`/ecommerce/admin/profile/subscriptions/${profileId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function cancelProfileSubscription(subscriptionId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/subscription/update/${subscriptionId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function createEnterprise(payload) {
  try {
    return await clientApi('/profile/organization', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getTaxRates() {
  try {
    return await clientApi('/ecommerce/admin/tax_rates', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function createProduct(payload) {
  try {
    return await clientApi(
      '/ecommerce/admin/physical_product',
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getBundles(cookies) {
  try {
    return await appRouterServerSideApi('/license/admin/get_bundles', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getBundleData(bundleId) {
  try {
    return await clientApi(`/license/admin/get_bundle_data/${bundleId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function lookupBundles(payload) {
  try {
    return await clientApi('/license/admin/bundle/search', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function linkBundles(payload) {
  try {
    return await clientApi('/license/admin/link_to_bundles', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function createItem(payload) {
  try {
    return await clientApi('/license/admin/item', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function createClaim(payload) {
  try {
    return await clientApi('/license/admin/claim', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function lookupItems(payload) {
  try {
    return await clientApi('/license/admin/item/search', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function lookupClaims(payload) {
  try {
    return await clientApi('/license/admin/claim/search', 'POST', null, JSON.stringify(payload));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function linkItemsOrClaims(payload) {
  try {
    return await clientApi(
      '/license/admin/link_items_or_claims',
      'POST',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function unlinkItemOrClaim(payload) {
  try {
    return await clientApi(
      '/license/admin/unlink_item_or_claim',
      'DELETE',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getNewsletterSubscriptions() {
  try {
    return await clientApi('/profile/preference/newsletters', 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getEnterpriseOrganizationSubscription(organizationId) {
  try {
    return await clientApi(`/ecommerce/admin/organization/subscription/${organizationId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function retrieveProfileBundles(profileId) {
  try {
    return await clientApi(`/license/admin/profile/profile_bundles/${profileId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateProduct(productId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/physical_product/${productId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateEnterpriseSubscription(subId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/subscription/enterprise/seats/${subId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPayoutList(cookies) {
  try {
    return await appRouterServerSideApi('/ecommerce/admin/reconciliation_report/list', cookies);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getPayoutDetails(reconciliation_report_id) {
  try {
    return await clientApi(
      `/ecommerce/admin/reconciliation_report/details/${reconciliation_report_id}`,
      'GET',
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateSubscription(subscriptionId, payload) {
  try {
    return await clientApi(
      `/ecommerce/admin/subscription/update/${subscriptionId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function voidInvoice(invoiceId = '') {
  try {
    return await clientApi(`/ecommerce/admin/invoice/void/${invoiceId}`, 'PATCH');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function reviseInvoice(stripeInvoiceId = '', payload = {}) {
  try {
    return await clientApi(
      `/ecommerce/admin/invoice/revise/${stripeInvoiceId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function retrieveInvoice(invoiceId = '') {
  try {
    return await clientApi(`/ecommerce/admin/invoice/retrieve/${invoiceId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getProfileById(profileId = '') {
  try {
    return await clientApi(`/profile/admin/profile/${profileId}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function creditOrVoidSubscriptionInvoice(subscriptionId = '', payload = {}) {
  try {
    return await clientApi(
      `/ecommerce/admin/subscription/invoice/credit/${subscriptionId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function creditOrderInvoice(orderId = '', payload = {}) {
  try {
    return await clientApi(
      `/ecommerce/admin/physical_order/invoice/credit/${orderId}`,
      'PATCH',
      null,
      JSON.stringify(payload),
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateProfile(body = {}) {
  try {
    return await clientApi(
      `/profile/admin/profile/${body?.profile_id}`,
      'PATCH',
      null,
      JSON.stringify(body),
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function getProfileAddresses(profileId = '') {
  try {
    return await clientApi(`/profile/admin/profile/addresses/${profileId}`, 'GET');
  } catch (e) {
    Sentry.captureException(e);
  }
}

export async function updateAddress(profileId, addressData) {
  try {
    let response = await getProfileAddresses(profileId);

    if (response && response.status === 200) {
      if (Array.isArray(response.data) && response.data.length) {
        const existingAddress = response.data.find((address) => address.type === addressData.type);
        if (existingAddress) {
          return await clientApi(
            '/profile/address',
            'PATCH',
            { address_id: existingAddress.id },
            JSON.stringify(addressData),
          );
        }
      }
    }

    response = await clientApi('/profile/address', 'POST', null, JSON.stringify(addressData));

    if (response && response.status === 201) {
      return await clientApi(
        '/profile/address/profile',
        'POST',
        null,
        JSON.stringify({
          profile_id: profileId,
          address_id: response.data.id,
          address: addressData,
        }),
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}
